const bdsUserUrl = '/bds-user';
export const API_USER_URL = {
  OAUTH_LOGIN: bdsUserUrl + '/auth/login',
  OAUTH_REGISTER: bdsUserUrl + '/auth/register',
  OAUTH_LOGOUT: bdsUserUrl + '/auth/logout',
  CUSTOMER: bdsUserUrl + '/customer',
  PROVINCE: bdsUserUrl + '/province',
  DISTRICT: (province_code: number) => bdsUserUrl + `/district?province_code=${province_code}`,
  WARD: (district_code: number) => bdsUserUrl + `/ward?district_code=${district_code}`,
  REFRESH_TOKEN: bdsUserUrl + '/auth/refresh-token',
  LOGIN_WITH_GOOGLE: bdsUserUrl + '/auth/login-with-google'
};
const bdsProductUrl = '/bds-product';
export const API_PRODUCT_URL = {
  POST_ESTATE: bdsProductUrl + '/landing-page/post-estate',
  UPLOAD_IMAGE: bdsProductUrl + '/upload-image',
  DELETE_IMAGE: bdsProductUrl + '/remove-image',
  LIST_POST: bdsProductUrl + `/landing-page/post-estate-by-customer`,
  DETAIL_POST: (id: string) => bdsProductUrl + `/landing-page/post-estate/${id}`,
  LIST_POST_ESTATE: bdsProductUrl + '/landing-page/get-all-post-estate',
  COMPARE_AROUND_AREA: bdsProductUrl + `/landing-page/post-estate/compare-around-area`,
  POST_ESTATE_REPORT: bdsProductUrl + '/landing-page/post-estate-report',
  COMPARE_AROUND_AREA_CITIES_HOT: bdsProductUrl + '/landing-page/post-estate/compare-around-area-cities-hot'
};
export const tokenKeyLocalStorage = 'token';
export const refreshTokenKeyLocalStorage = 'refresh_token';
export const domainUrl = process.env.NEXT_PUBLIC_CUSTOMER_URL ?? 'https://bds369.com/';
export const domainApi = 'https://shared.bds369.com/api/v1';
export const DOMAIN_IMAGE = 'https://shared.bds369.com';
const bdsBlogUrl = '/bds-blog';
export const API_BLOG_URL = {
  BLOG: bdsBlogUrl + '/blog',
  CATEGORY_CLIENT: bdsBlogUrl + '/category/client',
  SPOTLIGHT: bdsBlogUrl + '/spotlight/client'
};
export const bdsQuarter = process.env.NEXT_PUBLIC_QUARTER ?? 'Q2/2024';