import type { ResponseApi, User } from '@/types'

import { API_USER_URL } from '@/common'
import axiosInstance from '@/config/axiosInstance'

export const userService = {
  getUser: async () => {
    try {
      const { data } = await axiosInstance.get<ResponseApi<User>>(API_USER_URL.CUSTOMER)
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  logout: async () => {
    try {
      const { data } = await axiosInstance.post<ResponseApi<User>>(API_USER_URL.OAUTH_LOGOUT)
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  emailConfirm: async (token: string | null) => {
    try {
      const { data } = await axiosInstance.post(`/authentication/confirm`, { token: token })
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  changeEmailConfirm: async (token: string | null) => {
    try {
      const { data } = await axiosInstance.post(`/authentication/confirmChangeEmail`, { token: token })
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }
}
