export type User = {
  id: string
  last_name: string
  first_name: string
  phone: string
  address: string
  type_docs: number
  type_docs_name: string
  email: string
  image_id: string
  username: string
  password?: string
  token: string
  refresh_token: string
  district: DistrictType
  ward: WardType
}

export type ProvinceType = {
  code: number
  name: string
}

export type DistrictType = ProvinceType & {
  province_code: number
}

export type WardType = ProvinceType & {
  province_code: number
}

export enum UserTypeDocs {
  DEFAULT = 0,
  CCCD = 2,
  PASSPORT = 1
}

export const userQueryKey = 'user'
