import { useQuery } from "@tanstack/react-query";

import type { AxiosError } from "axios";

import { refreshTokenKeyLocalStorage, tokenKeyLocalStorage } from "@/common";
import axiosInstance from "@/config/axiosInstance";
import { HttpStatusEnum } from "@/enum";
import { homeService } from "@/services/home";
import { userService } from "@/services/user";
import { postLocationQueryKey, userQueryKey } from "@/types";

const isQuotaExceeded = (e: unknown) => {
  let quotaExceeded = false;
  if (e instanceof DOMException) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === "NS_ERROR_DOM_QUOTA_REACHED") {
            quotaExceeded = true;
          }
          break;
      }
    }
  }
  return quotaExceeded;
};

export const setToLocalStorage = (key: string, value: string) => {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, value);
    }
  } catch (e) {
    if (isQuotaExceeded(e)) {
      // Storage full, maybe notify user or do some clean-up
      console.error("Storage is not available");
    }
  }
};

export const setUserToLocalStorage = (id: string) => {
  setToLocalStorage("user", id);
};

export const removeUserFromLocalStorage = () => {
  axiosInstance.defaults.headers.common.Authorization = undefined;
  if (typeof window !== "undefined") {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem(tokenKeyLocalStorage);
    window.localStorage.removeItem(refreshTokenKeyLocalStorage);
  }
};

export const getUserFromLocalStorage = (): string | undefined => {
  try {
    if (typeof window !== "undefined") {
      const user = window.localStorage.getItem("user");

      if (user) {
        try {
          return user;
        } catch {
          return undefined;
        }
      }
      return undefined;
    }

    return undefined;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const useQueryUser = () => {
  const { data, error, isFetching, isSuccess } = useQuery({
    queryKey: [userQueryKey],
    queryFn: userService.getUser,
  });

  if (isSuccess) {
    if (data.http_status === HttpStatusEnum.SUCCESS)
      setUserToLocalStorage(data.data?.id as string);
  }
  return { user: data?.data, error, isFetching };
};

export const useEmailConfirm = (token: string | null) => {
  return useQuery<string, AxiosError<{ statusCode: number; message: string }>>({
    queryKey: ["email_confirm"],
    queryFn: () => userService.emailConfirm(token),
  });
};

export const useConfirmChangeEmail = (token: string | null) => {
  return useQuery<string, AxiosError<{ statusCode: number; message: string }>>({
    queryKey: ["confirm_change_email"],
    queryFn: () => userService.changeEmailConfirm(token),
  });
};

export const useQueryPostCitiesHot = (params: { intersect?: boolean }) => {
  const { data } = useQuery({
    queryKey: [postLocationQueryKey, params.intersect],
    queryFn: () => homeService.getPostCitiesHot(),
    enabled: !!params.intersect,
  });
  return { postCitiesHot: data?.data };
};
