import { range } from '@/utils';
export const postEstateQueryKey = 'postEstateQueryKey';
export const postLocationQueryKey = 'postLocationQueryKey';
export const EstateTypeEnum = {
  SELL: range(1, 11, 1).join(),
  RENT: range(12, 21, 1).join()
};
export type CitiesHotType = {
  code: number;
  name: string;
  popular_average_price: number;
  total_post_estate: number;
};