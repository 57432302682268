import type { ResponseApi, CitiesHotType } from '@/types'

import { API_PRODUCT_URL } from '@/common'
import axiosInstance from '@/config/axiosInstance'

export const homeService = {
  getPostCitiesHot: async () => {
    try {
      const { data } = await axiosInstance.get<ResponseApi<CitiesHotType[]>>(
        API_PRODUCT_URL.COMPARE_AROUND_AREA_CITIES_HOT
      )
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }
}
