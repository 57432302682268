import axios from 'axios';
import { getUserFromLocalStorage, removeUserFromLocalStorage } from '@/app/lib/query';
import { API_USER_URL, domainApi, refreshTokenKeyLocalStorage, tokenKeyLocalStorage } from '@/common';
import { HttpStatusEnum } from '@/enum';
let headers = {
  'Content-Type': 'application/json',
  'bds-public-key': process.env.NEXT_PUBLIC_BDS_KEY,
  Authorization: ''
};
if (typeof window !== 'undefined') {
  headers = {
    ...headers,
    Authorization: window?.localStorage?.getItem(tokenKeyLocalStorage) ? 'Bearer ' + window?.localStorage?.getItem(tokenKeyLocalStorage) : ''
  };
} else {
  headers = {
    ...headers
  };
}
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL ?? domainApi,
  withCredentials: true,
  headers,
  timeout: 10000
});
let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
}[] = [];
const processQueue = (error: null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};
axiosInstance.interceptors.response.use(response => {
  const {
    data,
    config
  } = response;
  if (typeof window !== 'undefined') {
    if (data.http_status === HttpStatusEnum.UNAUTHORIZED && window.localStorage.getItem(refreshTokenKeyLocalStorage)) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({
            resolve,
            reject
          });
        }).then(() => {
          return axios({
            ...config
          });
        }).catch(err => {
          return err;
        });
      }
      isRefreshing = true;
      return new Promise(resolve => {
        axios.post(API_USER_URL.REFRESH_TOKEN, {
          refresh_token: window.localStorage.getItem(refreshTokenKeyLocalStorage)
        }, {
          baseURL: process.env.NEXT_PUBLIC_API_URL ?? domainApi,
          timeout: 30000,
          withCredentials: true
        }).then(({
          data
        }) => {
          if (data.http_status === HttpStatusEnum.SUCCESS) {
            window.localStorage.setItem(tokenKeyLocalStorage, data.data?.token);
            processQueue(null, data.data?.token);
            resolve(axios({
              ...config,
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json, text/plain, */*',
                Authorization: data.data?.token
              }
            }));
          } else {
            processQueue(data, null);
            const user = getUserFromLocalStorage();
            removeUserFromLocalStorage();
            if (data.http_status === HttpStatusEnum.UNAUTHORIZED && user) {
              location.reload();
            }
            resolve(data);
          }
        }).then(() => {
          isRefreshing = false;
        });
      });
    }
  }
  return response;
});
export default axiosInstance;